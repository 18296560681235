<template>
  <div class="d-flex flex-column">
    <v-switch
      v-model="readonly"
      label="Read only"
    ></v-switch>
    <div class="d-flex flex-row">
      <v-card class="mr-4">
        <v-card-text>
          <v-textarea
            style="width: 600px;"
            label="Value (html)"
            rows="22"
            v-model="content"
          ></v-textarea>
        </v-card-text>
      </v-card>

      <div class="d-flex flex-column">
        <v-card class="mb-4">
          <v-card-text>

            <content-editor
              :content.sync="content"
              :readonly="readonly"
            />
          </v-card-text>
        </v-card>
        <!-- <v-card>
          <div class="d-flex flex-column">
            <label>Result</label>

            <div v-html="content" />

          </div>
        </v-card> -->

      </div>
    </div>

    <!-- <v-card>
      <content-editor
        :content.sync="content"
        :readonly="readonly"
      />
    </v-card> -->
  </div>

</template>

<script>

export default {
  components: {
    // TemplateText: () => import('@/components/template-text')

    ContentEditor: () => import('@/components/content-editor')
  },
  created () {
  },
  async mounted () {
    const result = await this.$http().get('/templateType/Reminder')
    this.tokens = result.data.tokens.filter(t => !t.isDeprecated)
  },
  data () {
    return {
      tokens: [],
      readonly: false,
      // content: '<p><span style="color: yellow; font-size: 30px;">1234567890</span></p>'
      content: `
      kasjd lkjasdkj alksjdlakjsdlakjsdlakj sdlkjaslkd jkla
      kasjd lkjasdkj alksjdlakjsdlakjsdlakj sdlkjaslkd jkla
      kasjd lkjasdkj alksjdlakjsdlakjsdlakj sdlkjaslkd jkla
      kasjd lkjasdkj alksjdlakjsdlakjsdlakj sdlkjaslkd jkla
       ola!

       </hr>
          <blockquote>
       111
          </blockquote>
        `
      // content: {
      //   html: '<div style="text-align: justify;" > <span style="font-size:18px;"><strong>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa&nbsp;</strong></span></div> <div style="text-align: center;"><span style="color:#EE82EE;"><span style="background-color: rgb(48, 48, 48);"><span style="font-size:18px;">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span></span></span></div> <div style="text-align: right;"><span style="font-size:18px;"><span style="color: rgb(255, 255, 255); text-align: justify; background-color: rgb(48, 48, 48);">aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span></span></div>'
      // }

    }
  },
  methods: {

  }

}
</script>

<style lang="stylus" scoped></style>
